// Header
.pulse-0 {
  animation: pulse 7s infinite alternate ease-in-out;
  transform-origin: center;
}
.pulse-1 {
  animation: pulse 3s infinite alternate ease-in-out;
}
.pulse-2 {
  animation: pulse 3.9s infinite alternate ease-in-out;
}
.pulse-3 {
  animation: pulse 3.5s infinite alternate ease-in-out;
}
.pulse-4 {
  animation: pulse 4.2s infinite alternate ease-in-out;
}

.pulse-0,
.pulse-1,
.pulse-2,
.pulse-3,
.pulse-4 {
  @include max-width(sm) {
    animation: none;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.95);
  }
}

.header-bg-image {
  position: absolute;
  z-index: -1;
  top: -150px;
  left: auto;
  right: -150px;
  width: 650px;
  height: auto;

  @include min-width(md) {
    top: -120px;
    left: auto;
    right: -200px;
    width: auto;
    height: auto;
  }

  @include min-width(lg) {
    top: -100px;
    left: auto;
    right: -100px;
    width: auto;
    height: auto;
  }

  @include max-width(sm) {
    display: none;
  }
}

.site-logo {
  svg {
    height: auto;

    @include min-width(md) {
      width: 225px;
    }

    // @include min-width(xl) {
    //   width: 250px;
    // }
  }
}

.site-description {
  .site-name {
    font-weight: 500;
  }

  @include max-width(xxs) {
    font-size: 12px;
  }
}

// Footer

.site-footer {
  font-size: 16px;
  padding: var(--space-xl) 0;
  background: rgb(64, 147, 201);
  background: linear-gradient(
    45deg,
    rgba(64, 147, 201, 1) 0%,
    rgba(53, 197, 146, 1) 50%,
    rgba(186, 227, 195, 1) 100%
  );

  .contacts__wrapper {
    color: var(--color-white);

    a {
      color: var(--color-white);

      &:hover {
        color: var(--primary-site-2);
      }
    }
  }

  @include max-width(xxs) {
    padding: var(--space-md) 0;
  }
}

// Sections

section {
  ul.ul-list {
    @include max-width(xxs) {
      font-size: 12px;
    }

    li {
      position: relative;
      list-style: none;
      // padding-left: var(--space-xl);
      padding-left: 4.75rem;
      margin-bottom: var(--space-sm);

      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0.725em;
        transform: translateY(-50%);
        height: 4px;
        width: var(--space-lg);
        background-color: var(--primary-site-1);
      }

      @include max-width(md) {
        &:before {
          left: var(--space-md);
        }
      }

      @include max-width(xxs) {
        padding-left: 4rem;
      }
    }
  }
}

.section-main {
  padding: var(--space-xl) 0;
  @include max-width(xxs) {
    padding: var(--space-md) 0;
  }

  br {
    @include max-width(xxs) {
      display: none;
    }
  }
}

.contacts {
  @include max-width(xxs) {
    padding-bottom: var(--space-md);
  }
}

.contacts__wrapper {
  display: inline-flex;
  flex-flow: column nowrap;
  font-weight: 600;

  @include max-width(xxs) {
    font-size: 12px;
  }

  a {
    @include min-width(xxs) {
      color: var(--color-contrast-high);
    }

    &:hover {
      color: var(--primary-site-1);
    }
  }
}

.section-info {
  margin: var(--space-xl) 0;

  @include max-width(xxs) {
    margin: var(--space-md) 0;
  }
}

.section-info {
  ul.ul-list {
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // row-gap: var(--space-xs);
    // column-gap: var(--space-md);
    column-count: 2;
    column-gap: var(--space-lg);

    @include max-width(md) {
      // grid-template-columns: 1fr;
      column-count: 1;
    }

    li {
      // margin-bottom: 0;
    }
  }

  .license-list {
    font-size: 16px;

    @include max-width(xxs) {
      font-size: 12px;
    }
  }

  .license-item {
    position: relative;
    padding-left: 4.75rem;
    margin-bottom: var(--space-lg);

    @include max-width(xxs) {
      padding-left: 4rem;
    }

    &:before {
      content: "";
      position: absolute;
      left: 1em;
      top: 1.125em;
      transform: translateY(-50%);
      height: 42px;
      width: 42px;
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTYiIGhlaWdodD0iNTYiIHZpZXdCb3g9IjAgMCA1NiA1NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0yOS4wMzg1IDUuNTA5NDNMMjggNC41MTI4OEwyNi45NjE0IDUuNTA5NDNMMjIuODQ0MyA5LjQ2MDA4TDE3LjEwMzkgOS41NDQ1NkwxNS42NDggOS41NjU5OEwxNS42MjYxIDExLjAyMThMMTUuNTQwOSAxNi42ODAxTDExLjU1MTQgMjAuNzQzMUwxMC41MTk1IDIxLjc5NEwxMS41NTE0IDIyLjg0NUwxNS41NDA5IDI2LjkwOEwxNS42MjYxIDMyLjU2NjNMMTUuNjQ4IDM0LjAyMjFMMTcuMTAzOSAzNC4wNDM1TDE5LjY2NTIgMzQuMDgxMlY0OC45OTA5VjUxLjQwNTVMMjEuODI5OSA1MC4zMzU2TDI4IDQ3LjI4NThMMzQuMTcwMSA1MC4zMzU2TDM2LjMzNDggNTEuNDA1NVY0OC45OTA5VjM0LjA4MTJMMzguODk2MSAzNC4wNDM1TDQwLjM1MiAzNC4wMjIxTDQwLjM3MzkgMzIuNTY2M0w0MC40NTkxIDI2LjkwOEw0NC40NDg1IDIyLjg0NUw0NS40ODA0IDIxLjc5NEw0NC40NDg1IDIwLjc0MzFMNDAuNDU5MSAxNi42ODAxTDQwLjM3MzkgMTEuMDIxOEw0MC4zNTIgOS41NjU5OEwzOC44OTYxIDkuNTQ0NTZMMzMuMTU1NyA5LjQ2MDA4TDI5LjAzODUgNS41MDk0M1pNMzMuMzM0OCAzNC4xMjU0TDMzLjE1NTcgMzQuMTI4TDI5LjAzODUgMzguMDc4NkwyOCAzOS4wNzUyTDI2Ljk2MTQgMzguMDc4NkwyMi44NDQzIDM0LjEyOEwyMi42NjUyIDM0LjEyNTRWNDYuNTc2M0wyNy4zMzUzIDQ0LjI2NzlMMjggNDMuOTM5NEwyOC42NjQ2IDQ0LjI2NzlMMzMuMzM0OCA0Ni41NzYzVjM0LjEyNTRaTTI0LjQ5NTMgMTIuMDMzNUwyOCA4LjY3MDYxTDMxLjUwNDcgMTIuMDMzNUwzMS45MzA3IDEyLjQ0MjRMMzIuNTIxMSAxMi40NTExTDM3LjM5NjEgMTIuNTIyOEwzNy40Njg1IDE3LjMyNTRMMzcuNDc3NSAxNy45MjU1TDM3Ljg5OCAxOC4zNTM3TDQxLjI3NiAyMS43OTRMMzcuODk4IDI1LjIzNDNMMzcuNDc3NSAyNS42NjI2TDM3LjQ2ODUgMjYuMjYyN0wzNy4zOTYxIDMxLjA2NTNMMzIuNTIxMSAzMS4xMzdMMzEuOTMwNyAzMS4xNDU3TDMxLjUwNDcgMzEuNTU0NUwyOCAzNC45MTc1TDI0LjQ5NTMgMzEuNTU0NUwyNC4wNjkzIDMxLjE0NTdMMjMuNDc4OCAzMS4xMzdMMTguNjAzOCAzMS4wNjUzTDE4LjUzMTUgMjYuMjYyN0wxOC41MjI1IDI1LjY2MjZMMTguMTAyIDI1LjIzNDNMMTQuNzI0IDIxLjc5NEwxOC4xMDIgMTguMzUzN0wxOC41MjI1IDE3LjkyNTVMMTguNTMxNSAxNy4zMjU0TDE4LjYwMzggMTIuNTIyOEwyMy40Nzg4IDEyLjQ1MTFMMjQuMDY5MyAxMi40NDI0TDI0LjQ5NTMgMTIuMDMzNVoiIGZpbGw9IiMwMkJFNTAiLz4KPC9zdmc+Cg==");
      background-size: 42px 42px;
    }
  }
}
