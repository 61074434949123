$breakpoints: (
  xxs: 29.375rem,
  // ~470px
    xs: 32rem,
  // ~512px
    sm: 48rem,
  // ~768px
    md: 64rem,
  // ~1024px
    lg: 80rem,
  // ~1280px
    xl: 90rem // ~1440px
);

@mixin breakpoint($breakpoint, $logic: false) {
  @if ($logic) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}

@mixin min-width($breakpoint, $logic: false) {
  @if ($logic) {
    @media #{$logic} and (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  } @else {
    @media (min-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}

@mixin max-width($breakpoint, $logic: false) {
  @if ($logic) {
    @media #{$logic} and (calc((max-width: map-get($map: $breakpoints, $key: $breakpoint)) - 1px)) {
      @content;
    }
  } @else {
    @media (max-width: map-get($map: $breakpoints, $key: $breakpoint)) {
      @content;
    }
  }
}
