:root,
[data-theme="default"] {
  --primary-site-1: #02be50;
  --primary-site-2: #008467;

  --secondary-site-1: #4093c9;
  --secondary-site-2: #35c592;
  --secondary-site-3: #bae3c3;

  // main
  @include defineColorHSL(--color-primary-darker, 215, 94%, 33%);
  @include defineColorHSL(--color-primary-dark, 215, 94%, 43%);
  @include defineColorHSL(--color-primary, 215, 94%, 53%);
  @include defineColorHSL(--color-primary-light, 215, 94%, 63%);
  @include defineColorHSL(--color-primary-lighter, 215, 94%, 73%);

  @include defineColorHSL(--color-accent-darker, 354, 75%, 32%);
  @include defineColorHSL(--color-accent-dark, 354, 75%, 42%);
  @include defineColorHSL(--color-accent, 354, 75%, 52%);
  @include defineColorHSL(--color-accent-light, 354, 75%, 62%);
  @include defineColorHSL(--color-accent-lighter, 354, 75%, 72%);

  @include defineColorHSL(--color-black, 240, 8%, 12%);
  @include defineColorHSL(--color-white, 0, 0%, 100%);

  // feedback
  @include defineColorHSL(--color-warning-darker, 30, 100%, 33%);
  @include defineColorHSL(--color-warning-dark, 30, 100%, 43%);
  @include defineColorHSL(--color-warning, 30, 100%, 53%);
  @include defineColorHSL(--color-warning-light, 30, 100%, 63%);
  @include defineColorHSL(--color-warning-lighter, 30, 100%, 73%);

  @include defineColorHSL(--color-success-darker, 111, 42%, 27%);
  @include defineColorHSL(--color-success-dark, 111, 42%, 37%);
  @include defineColorHSL(--color-success, 111, 42%, 47%);
  @include defineColorHSL(--color-success-light, 111, 42%, 57%);
  @include defineColorHSL(--color-success-lighter, 111, 42%, 67%);

  @include defineColorHSL(--color-error-darker, 356, 80%, 31%);
  @include defineColorHSL(--color-error-dark, 356, 80%, 41%);
  @include defineColorHSL(--color-error, 356, 80%, 51%);
  @include defineColorHSL(--color-error-light, 356, 80%, 61%);
  @include defineColorHSL(--color-error-lighter, 356, 80%, 71%);

  // color contrasts
  @include defineColorHSL(--color-bg, 0, 0%, 100%);
  @include defineColorHSL(--color-contrast-lower, 240, 8%, 95%);
  @include defineColorHSL(--color-contrast-low, 227, 11%, 84%);
  @include defineColorHSL(--color-contrast-medium, 226, 10%, 50%);
  @include defineColorHSL(--color-contrast-high, 223, 33%, 23%);
  @include defineColorHSL(--color-contrast-higher, 217, 63%, 14%);
}
