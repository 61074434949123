:root {
  // font family
  --font-primary: "Montserrat", sans-serif;

  // body font size
  --text-base-size: 1em;

  // type scale
  --text-scale-ratio: 1.25;
  --text-xs: calc((1em / var(--text-scale-ratio)) / var(--text-scale-ratio));
  --text-sm: calc(var(--text-xs) * (var(--text-scale-ratio) + 0.25));
  --text-md: calc(
    var(--text-sm) * var(--text-scale-ratio) * var(--text-scale-ratio)
  );
  --text-lg: calc(var(--text-md) * var(--text-scale-ratio));
  --text-xl: calc(var(--text-lg) * var(--text-scale-ratio));
  --text-xxl: calc(var(--text-xl) * var(--text-scale-ratio));
  // --text-xxl: 60px;
  --text-xxxl: calc(var(--text-xxl) * var(--text-scale-ratio));

  // line-height
  --body-line-height: 1.6;
  --heading-line-height: 1.4;

  // capital letters - used in combo with the lhCrop mixin
  --font-primary-capital-letter: 1;
}

@supports (--css: variables) {
  :root {
    @include breakpoint(md) {
      --text-base-size: 1.25em;
      --text-scale-ratio: 1.4;
    }

    @include max-width(xxs) {
      --text-scale-ratio: 1.16;
    }

    // @media (max-width: 469px) {
    //   --text-scale-ratio: 1.16;
    // }
  }
}

body {
  font-family: var(--font-primary);
  overflow-x: hidden;
}

a,
.link {
  color: var(--primary-site-1);
  text-decoration: none;
  transition: color var(--trans-time) var(--trans-curve);

  &:hover {
    color: var(--primary-site-2);
  }
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-primary);
  font-weight: 800;
}

.c-primary-1 {
  color: var(--primary-site-1);
}

.c-primary-2 {
  color: var(--primary-site-2);
}

.c-gray {
  color: var(--color-contrast-medium);
}
